import CFBMap from "./components/CFBMap.jsx";

import React from 'react'

const App = () => {
  return (
    <CFBMap/>
  )
}

export default App;