import axios from "axios";

const apiURL = process.env.NODE_ENV === 'development' ? 'https://localhost:7055' : 'https://api.cfbtracker.com';
const header = process.env.NODE_ENV === 'development' ? {} : {'Ocp-Apim-Subscription-Key': process.env.REACT_APP_CFB_API_KEY};

export const GetTeamsFBS = new Promise((resolve, reject) => {
    axios.get(apiURL+'/team', {headers: header})
        .then((response) => {
            resolve(response.data);
        })
        .catch((err) => {
            reject(err);
        })
});

export const GetGames = new Promise((resolve, reject) => {
    axios.get(apiURL+'/games?year=2021&week=2', {headers: header})
        .then((response) => {
            resolve(response.data);
        })
        .catch((err) => {
            reject(err);
        })
});

// export const GetTeamsFBS = new Promise((resolve, reject) => {
//     axios.get('https://api.cfbtracker.com/team', {
//         headers: {
//           'Ocp-Apim-Subscription-Key': process.env.REACT_APP_CFB_API_KEY
//         }
//        })
//         .then((response) => {
//             resolve(response.data);
//         })
//         .catch((err) => {
//             reject(err);
//         })
// });
