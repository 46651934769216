export const StadiumSideBar = (props) => {
    const {name, city, state, elevation, capacity, yearConstructed, grass, dome} = props.teamInfo.location;
    const roof = dome ? 'Dome' : 'Open Air';
    const field = grass ? 'Grass' : 'Artificial';
    return (
        <div style={{ width: '15vw', textAlign: 'center'}}>
            <button className='btn' onClick={props.handleMapReset} style={{marginBottom: '3rem'}}>Return To Map</button>
            <div style={{verticalAlign: 'middle'}}>
                <h2>{name}</h2>
                <h4>{city + ', ' + state}</h4>
                <ul>
                    <li><small>{'Elevation: ' + Math.round(elevation) + 'ft.'}</small></li>
                    <li><small>{'Year Built: ' + yearConstructed}</small></li>
                    <li><small>{'Capacity:   ' + capacity}</small></li>
                    <li><small>{'Roof:       ' + roof}</small></li>
                    <li><small>{'Field:      ' + field}</small></li>
                </ul>
            </div>
        </div>
    );
};

export const LandingSideBar = () => {
    return (
        <div style={{ width: '15vw', textAlign: 'center'}}>
            {/* <button className='btn' onClick={props.handleMapReset} style={{marginBottom: '3rem'}}>Return To Map</button> */}
            <div style={{verticalAlign: 'middle'}}>
                <h2>CFBtracker.com</h2>
                <h4>Click on a team's logo to take a look at their stadium.</h4>
            </div>
        </div>
    );
};