import { useEffect } from 'react';
import useEcharts from 'react-hooks-echarts';
import { option } from '../lib/ChartOption.js';
import { StyledMapType2 } from '../lib/GMapTools.js';
import { Easing, Tween, update } from "@tweenjs/tween.js";
import 'echarts-extension-gmap';

const StadiumChart = ({teams, mapView, fullZoom, logoScale, setMapView, setDisplayColors, setIsZooming}) => {
    const [chartRef, ref] = useEcharts();

    useEffect(() => {
        //Initialize echart
        const chart = chartRef.current;
        chart.setOption(option(teams, logoScale));
        chart.on('click', params => {   
            setMapView({
                mapTypeId: 'satellite', 
                longitude: params.value[0],
                latitude: params.value[1],
                zoom: fullZoom,
                teamInfo: params.value[3],
            });
            setDisplayColors([params.value[3].color, params.value[3].altColor]);
        })

        //Initialize gmap
        const gmap = chart
            .getModel()
            .getComponent('gmap')
            .getGoogleMap();
        gmap.mapTypes.set('styled_map', StyledMapType2);
        gmap.setMapTypeId('satellite');
        gmap.setOptions({ disableDefaultUI: true} );        
    }, [chartRef, teams, setMapView, fullZoom, logoScale, setDisplayColors]);

    useEffect(() => {
        var chart = chartRef.current;
        var gmap = chart
            .getModel()
            .getComponent('gmap')
            .getGoogleMap();
        const {center: oldCenter, zoom: oldZoom} = chart.getOption().gmap[0];
        const {mapTypeId, longitude, latitude, zoom} = mapView;

        //Change Logo Opacity
        const setLogoOpacity = (opacity) => {
            var options = chartRef.current.getOption();
            options.series[0].itemStyle.opacity = opacity;
            chart.setOption(options);
        }

        //Animated Zoom
        setIsZooming(true);
        const cameraOptions = {
            tilt: 0,
            heading: 0,
            zoom: oldZoom,
            center: { lat: oldCenter[1], lng: oldCenter[0] },
          };
        
        if(oldZoom < zoom){
            new Tween(cameraOptions) // Create a new tween that modifies 'cameraOptions'.
            .to({ tilt: 0, heading: 0, zoom: oldZoom, center: { lat: latitude, lng: longitude } }, 1000) // Move to destination in 15 second.
            .easing(Easing.Quadratic.Out) // Use an easing function to make the animation smooth.
            .onUpdate(() => {
              gmap.moveCamera(cameraOptions);
            })
            .onComplete(() => {
                setLogoOpacity(0.2);
                gmap.setMapTypeId(mapTypeId);
                const cameraOptions2 = {
                    tilt: 0,
                    heading: 0,
                    zoom: oldZoom,
                    center: { lat: latitude, lng: longitude },
                  };
                new Tween(cameraOptions2) // Create a new tween that modifies 'cameraOptions'.
                .to({ tilt: 0, heading: 0, zoom: zoom, center: { lat: latitude, lng: longitude } }, (zoom-oldZoom)*600) // Move to destination in 15 second.
                .easing(Easing.Quadratic.InOut) // Use an easing function to make the animation smooth.
                .onUpdate(() => {
                gmap.moveCamera(cameraOptions2);
                })
                .onComplete(() => setIsZooming(false))
                .start(); // Start the tween immediately.
            })
            .start(); // Start the tween immediately.

        } else {
            new Tween(cameraOptions) // Create a new tween that modifies 'cameraOptions'.
            .to({ tilt: 0, heading: 0, zoom: zoom, center: { lat: oldCenter[1], lng: oldCenter[0] } }, (oldZoom-zoom)*600) // Move to destination in 15 second.
            .easing(Easing.Quadratic.InOut) // Use an easing function to make the animation smooth.
            .onUpdate(() => {
              gmap.moveCamera(cameraOptions);
            })
            .onComplete(() => {
                setLogoOpacity(1);
                gmap.setMapTypeId(mapTypeId);
                const cameraOptions2 = {
                    tilt: 0,
                    heading: 0,
                    zoom: zoom,
                    center: { lat: oldCenter[1], lng: oldCenter[0] },
                  };
                new Tween(cameraOptions2) // Create a new tween that modifies 'cameraOptions'.
                .to({ tilt: 0, heading: 0, zoom: zoom, center: { lat: latitude, lng: longitude } }, 1000) // Move to destination in 15 second.
                .easing(Easing.Quadratic.In) // Use an easing function to make the animation smooth.
                .onUpdate(() => {
                gmap.moveCamera(cameraOptions2);
                })
                .onComplete(() => setIsZooming(false))
                .start(); // Start the tween immediately.
            })
            .start(); // Start the tween immediately.

        }

        // Setup the animation loop.
        function animate(time) {
          requestAnimationFrame(animate);
          update(time);
        }
      
        requestAnimationFrame(animate);
    }, [chartRef, mapView, fullZoom, setIsZooming]);

    return (
        <div ref={ref} className="echarts-google-map map" />
    )
}

export default StadiumChart;