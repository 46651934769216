const randomStadiumPosition = (teams) => {
  const randomLocation = teams[Math.floor(Math.random() * teams.length)].location;
  return [randomLocation.longitude, randomLocation.latitude];
}

export const option = (teams, logoScale) => {
  return {
    // google map component
    gmap: {
      // See https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions for details
      center: randomStadiumPosition(teams), //[-98, 39],
      zoom: 17,
      renderOnMoving: true,
      echartsLayerZIndex: 2019,
      roam: true
    },
    tooltip : {
      trigger: 'item'
    },
    animation: true,
    series: {
      type: 'scatter',
      coordinateSystem: 'gmap',
      encode: {
        value: 2,
        lng: 0,
        lat: 1
      },

      name: 'FBS Schools',
      data: teams.map(t => {
        return {
          name: t.mascot,
          value: [t.location.longitude, t.location.latitude, t.location.capacity, t]
        }}),
      symbol: function (val) {
        return 'image://' + val[3].logos[0].replace('http:', 'https:');
      },
      symbolSize: function (val) {
        return ((val[2] / 4000)+15)/logoScale;
      },
      itemStyle: {
        color: function (val) {
          return val.data.value[3].color;
        },
        opacity: 1
      }
    }
  }
};