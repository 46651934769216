import { useState, useEffect } from 'react';
import { GetTeamsFBS, GetGames } from '../lib/CFBData.js';
import StadiumChart from './StadiumChart.jsx';
import { LandingSideBar, StadiumSideBar } from './SideBar.jsx';
import useWindowDimensions from '../hooks/useWindowDimensions.jsx';
import 'echarts-extension-gmap';
import './cfbmap.css';


const landingDisplayColors = ['#f2f2f2', '#444444'];

const CFBMap = () => {
    const { width } = useWindowDimensions();
    const landingView = {
        mapTypeId: 'styled_map', 
        longitude: -98,
        latitude: 39,
        zoom: width > 1024 ? 4.7 : width > 600 ? 4 : 3.2,
        teamInfo: {}
    };
    const fullZoom = 17//width > 600 ? 17.5 : 17;
    const logoScale = width > 600 ? 1 : 2;

    const [mapView, setMapView] = useState(landingView);
    const [displayColors, setDisplayColors] = useState(landingDisplayColors);
    const [isZooming, setIsZooming] = useState(false);
    const [teams, setTeams] = useState([]);

    useEffect(() => {
        console.log(process.env.NODE_ENV);
        GetGames.then(console.log).catch(console.log);
        GetTeamsFBS.then(setTeams).catch(console.log);
    }, []);


    const handleMapReset = () => {
        setDisplayColors(landingDisplayColors);
        setMapView(landingView);
    };

    const sideBar = () => {
        if(mapView.mapTypeId === 'styled_map' || isZooming){
            return <LandingSideBar/>;
        } else {
            return (
                <StadiumSideBar
                    teamInfo={mapView.teamInfo}
                    handleMapReset={handleMapReset}
                />);
        }
    };

    const stadiumChart = () => {
        if(teams.length === 0){
            return (<div>Loading...</div>);
        } else {
            return (
                <StadiumChart
                    teams={teams}
                    mapView={mapView}
                    fullZoom={fullZoom}
                    logoScale={logoScale}
                    setMapView={setMapView}
                    setDisplayColors={setDisplayColors}
                    setIsZooming={setIsZooming}
                />
            );
        }

    }
    return (
        <div className='map__container' style={{ backgroundColor: displayColors[0], color: displayColors[1]}}>
            {stadiumChart()}
            {sideBar()}
        </div>
    )
}

export default CFBMap;